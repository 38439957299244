import * as React from 'react'
import { SVGProps } from 'react'

const ArrowRightSmall = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={6} height={12} {...props}>
		<path d="M0 .343L5.657 6 0 11.657z" />
	</svg>
)

export default ArrowRightSmall
