import * as React from 'react'
import { SVGProps } from 'react'

const Slash = (props: SVGProps<SVGSVGElement>) => (
	<svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13 1L1.295 12.705"
			stroke="currentColor"
			strokeWidth="1.5"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
		/>
	</svg>
)

export default Slash
