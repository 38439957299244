import { Children, Dispatch, SetStateAction, useEffect } from 'react'

import { ArrowLeft, ArrowRight } from '@/icons'
import { useKeenSlider } from 'keen-slider/react'
import debounce from 'lodash/debounce'
import tailwindConfig from 'tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'

type Props = {
	className?: string
	dataTestId?: string
	children?: React.ReactNode[]
	loop?: boolean
	itemsShown?: number
	autoplay?: number
	skip: number
	setActiveSlide?: Dispatch<SetStateAction<number>>
}

const fullConfig = resolveConfig(tailwindConfig)

function getMobileBreakpoint() {
	// @ts-ignore tailwind types are generic
	const breakpoint = fullConfig?.theme?.screens?.sm

	if (breakpoint == null) {
		console.warn('Missing mobile breakpoint, Carousel falling back to 576px.')

		return '(min-width: 576px)'
	}

	return `(min-width: ${breakpoint})`
}

function getTabletBreakpoint() {
	// @ts-ignore tailwind types are generic
	const breakpoint = fullConfig?.theme?.screens?.lg

	if (breakpoint == null) {
		console.warn('Missing tablet breakpoint, Carousel falling back to 1024px.')

		return '(min-width: 1024px)'
	}

	return `(min-width: ${breakpoint})`
}

export function Carousel({
	className,
	children,
	skip,
	setActiveSlide,
	dataTestId = 'carousel',
	loop = true,
	itemsShown = 4,
	autoplay = 0
}: Props) {
	const slides = Children.toArray(children)
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
		{
			breakpoints: {
				[getMobileBreakpoint()]: {
					slides: { perView: itemsShown - 1, origin: 'auto' }
				},
				[getTabletBreakpoint()]: {
					slides: { perView: itemsShown, origin: 'auto' }
				}
			},
			loop,
			slides: { perView: 'auto', origin: 'center' },
			selector: ':scope > div',
			slideChanged(e) {
				if (!setActiveSlide) return
				setActiveSlide(e.track.details.rel)
			}
		},
		[
			// resize
			(slider) => {
				const debouncedUpdate = debounce(() => slider.update(), 100)
				const observer = new ResizeObserver(() => debouncedUpdate())

				slider.on('created', () => observer.observe(slider.container))
				slider.on('destroyed', () => observer.unobserve(slider.container))
			}
		]
	)

	useEffect(() => {
		if (autoplay > 0) {
			const intervalId = setInterval(() => instanceRef.current?.next(), autoplay * 1000)

			return () => clearInterval(intervalId)
		}
	}, [instanceRef, autoplay])

	function prevSlide() {
		const slider = instanceRef.current
		if (!slider) return
		slider.moveToIdx(slider.track.details.rel - skip)
	}

	function nextSlide() {
		const slider = instanceRef.current
		if (!slider) return
		slider.moveToIdx(slider.track.details.rel + skip)
	}

	return (
		<div className={className} data-testid={dataTestId}>
			{slides.length > 0 ? (
				<div
					tabIndex={-1}
					onKeyDown={(e) => {
						switch (e.key) {
							default:
								break
							case 'Left':
							case 'ArrowLeft':
								prevSlide()
								break
							case 'Right':
							case 'ArrowRight':
								nextSlide()
								break
						}
					}}
					className="-mx-0 sm:-mx-4 focus:outline-0"
				>
					<div
						className="relative flex touch-pan-y pb-10 select-none w-full overflow-hidden focus:outline-0"
						ref={sliderRef}
					>
						{slides.map((slide, index) => (
							<div
								key={index}
								className="min-w-[calc(100%-64px)] sm:min-w-[33.33%] lg:min-w-[25%] px-4 sm:px-4 [&>div]:h-full [&>div>div]:items-start"
							>
								{slide}
							</div>
						))}
					</div>
					<div className="flex gap-5 justify-center text-gray-600">
						<ArrowLeft className="cursor-pointer hidden md:block" onClick={prevSlide} />
						<ArrowRight className="cursor-pointer hidden md:block" onClick={nextSlide} />
						<ArrowLeft
							className="cursor-pointer md:hidden"
							onClick={() => instanceRef.current?.prev()}
						/>
						<ArrowRight
							className="cursor-pointer md:hidden"
							onClick={() => instanceRef.current?.next()}
						/>
					</div>
				</div>
			) : (
				<p className="text py-4 text-center text-gray-700">There are no slides</p>
			)}
		</div>
	)
}
