const { fontFamily, screens } = require('tailwindcss/defaultTheme')

/** @type {import('tailwindcss').Config} */

module.exports = {
	content: [
		'./app/**/*.{js,ts,jsx,tsx}',
		'./pages/**/*.{js,ts,jsx,tsx}',
		'./components/**/*.{js,ts,jsx,tsx}',

		// Or if using `src` directory:
		'./src/**/*.{js,ts,jsx,tsx}'
	],
	theme: {
		container: {
			center: true,
			screens: {
				DEFAULT: '1156px'
			},
			padding: {
				DEFAULT: '1rem'
			}
		},
		colors: {
			transparent: 'transparent',
			current: 'currentColor',
			'bc-blue': '#0d52ff',
			'bc-black': '#121118',
			'bc-green': '#03eada',
			'bc-green-100': '#408f91',
			'bc-purple': '#4e00c0',
			'bc-violet': '#a131ed',
			'bc-pink': '#f849a0',
			black: '#000000',
			'black-100': '#121118',
			'black-200': '#263237',
			'black-300': '#4f5b62',
			blue: {
				100: '#011b86',
				200: '#0b42cc',
				300: '#01dbff'
			},
			gray: {
				100: '#1b1922',
				200: '#23212b',
				300: '#2b2935',
				400: '#34313f',
				500: '#4c4b58',
				600: '#5d5d69',
				700: '#6d6e7a',
				800: '#8e919c',
				900: '#afb4be',
				1000: '#d1d7e0',
				1100: '#dbdfe7',
				1200: '#e4e7ed',
				1300: '#edeff3',
				1400: '#f6f7f9'
			},
			green: {
				100: '#016002',
				200: '#50b315',
				300: '#91e026'
			},
			orange: {
				100: '#ff6922',
				200: '#ffbe38',
				300: '#ffe956'
			},
			purple: {
				100: '#4c37aa',
				200: '#9a7cf7',
				300: '#cfa8ff'
			},
			red: {
				100: '#7f0000',
				200: '#b51c1c',
				300: '#ee5546'
			},
			white: '#ffffff',
			yellow: {
				100: '#c4a502',
				200: '#fbd635',
				300: '#ffff6b',
				400: '#f19c38'
			}
		},
		screens: {
			'max-sm': { max: '639px' },
			// => @media (max-width: 639px) { ... }

			'max-md': { max: '767px' },
			// => @media (max-width: 767px) { ... }

			'max-lg': { max: '1023px' },
			// => @media (max-width: 1023px) { ... }

			'max-xl': { max: '1279px' },
			// => @media (max-width: 1279px) { ... }

			'max-2xl': { max: '1535px' },
			// => @media (max-width: 1535px) { ... }

			'lg-plus': '1025px',
			// => @media (min-width: 1025px) { ... }

			'xl-plus': '1440px',
			// => @media (min-width: 1440px) { ... }

			...screens
		},
		extend: {
			animation: {
				'loading-pulse': 'loading-pulse 2s linear infinite',
				'subnav-enter': 'subnav-enter 250ms ease-in-out'
			},
			backgroundImage: {
				'rich-text':
					'linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, 1)),linear-gradient(to left, rgba(3, 234, 218, .5), rgba(13, 82, 255, 0.5)),linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))'
			},
			borderRadius: {
				'5xl': '50px'
			},
			fontFamily: {
				sans: ['Gotham', ...fontFamily.sans],
				'gotham-condensed': ['Gotham Condensed', ...fontFamily.sans],
				'gotham-narrow': ['Gotham Narrow', ...fontFamily.sans]
			},
			keyframes: {
				'loading-pulse': {
					'0%, 40%, 100%': { opacity: 0.5, transform: 'scaleY(1)' },
					'20%': { opacity: 1, transform: 'scaleY(1.1) translateY(-2%)' }
				},
				'subnav-enter': {
					'0%': { opacity: 0, transform: 'translateY(10px)' },
					'100%': { opacity: 1, transform: 'translateY(0)' }
				}
			},
			spacing: {
				4.5: '1.125rem', // 18px
				5.5: '1.375rem', // 22px
				6.5: '1.625rem', // 26px
				7.5: '1.875rem', // 30px
				15: '3.75rem', // 60px
				25: '6.25rem' // 100px
			},
			typography: ({ theme }) => ({
				quoteless: {
					css: {
						'blockquote p:first-of-type::before': { content: 'none' },
						'blockquote p:first-of-type::after': { content: 'none' }
					}
				},
				DEFAULT: {
					css: {
						color: '#121118',
						h1: {
							'font-size': '3rem',
							'line-height': '3.5rem',
							'font-weight': '700',
							'@screen max-lg': {
								'font-size': '2.25rem',
								'line-height': '1.2'
							},
							'@screen max-md': {
								'font-size': '2.0rem',
								'line-height': '1.2'
							}
						},
						h2: {
							'font-size': '2.625rem',
							'line-height': '3.125rem',
							'font-weight': '700',
							'@screen max-lg': {
								'font-size': '1.94rem',
								'line-height': '1.2'
							},
							'@screen max-md': {
								'font-size': '1.75rem',
								'line-height': '1.2'
							}
						},
						h3: {
							'font-size': '2.125rem',
							'line-height': '2.75rem',
							'font-weight': '700',
							'@screen max-lg': {
								'font-size': '1.69rem',
								'line-height': '1.3'
							},
							'@screen max-md': {
								'font-size': '1.625rem',
								'line-height': '1.3'
							}
						},
						h4: {
							'font-size': '1.5rem',
							'line-height': '2rem',
							'font-weight': '700'
						},
						h5: {
							'font-size': '1.25rem',
							'line-height': '1.75rem',
							'font-weight': '500'
						},
						h6: {
							'font-size': '1rem',
							'line-height': '1.5rem',
							'font-weight': '500'
						},
						a: {
							'text-decoration': 'none',
							color: '#0d52ff',
							'&>b': {
								'font-weight': '500'
							},
							'&:hover': {
								color: '#121118'
							}
						},
						p: {
							'font-size': '1rem',
							'line-height': '1.625rem',
							'font-weight': '400',
							color: '#5d5d69'
						},
						ul: {
							'margin-left': '2rem',
							'padding-left': '0rem',
							'list-style-type': 'none',
							'&>li': {
								position: 'relative',
								'&:before': {
									content: "'◢'",
									display: 'inline-block',
									'font-size': '.5rem',
									'line-height': '1.625rem',
									position: 'absolute',
									left: '-1rem',
									color: '#0d52ff'
								}
							}
						},
						img: {
							margin: '0 auto',
							'margin-bottom': '1rem'
						},
						ol: {
							'padding-left': '0rem',
							'list-style-type': 'none',
							'counter-reset': 'item',
							'margin-left': '2rem',
							'margin-bottom': '1rem',
							'margin-top': '0rem',
							'margin-right': '0rem',
							'&>li': {
								'margin-top': '1rem',
								display: 'flex',
								flexDirection: 'column',
								'&>p:before': {
									content: "counters(item,'.') '. '",
									'counter-increment': 'item',
									'margin-left': '-1.125rem',
									color: '#0d52ff'
								},
								'&>p': {
									flex: 1
								},
								'&>img': {
									'margin-bottom': '0rem',
									'margin-top': '0rem'
								}
							}
						},
						li: {
							position: 'relative',
							'font-size': '1rem',
							'line-height': '1.625rem',
							'&>p': {
								margin: '0'
							}
						},
						blockquote: {
							margin: '0 auto',
							padding: '2.5rem 3rem',
							'border-left': '0',
							color: '#4C4B58',
							'font-size': '1.25rem',
							'font-style': 'normal',
							'hanging-punctuation': 'first'
						},
						'blockquote p': {
							fontWeight: '500',
							fontSize: '1.25rem',
							color: theme('colors.bc-black')
						},
						'blockquote p em, blockquote p i': {
							fontSize: '0.8125rem',
							textTransform: 'uppercase',
							lineHeight: '25px',
							fontWeight: '600'
						},
						'blockquote p:first-of-type::before': {
							content: ''
						},
						'blockquote p:last-of-type::after': {
							content: ''
						}
					}
				}
			}),
			fontSize: {
				display: ['4.3125rem', { lineHeight: '4.875rem', fontWeight: '700' }],
				h1: ['3rem', { lineHeight: '3.5rem', fontWeight: '700' }],
				h2: ['2.625rem', { lineHeight: '3.125rem', fontWeight: '700' }],
				h3: ['2.125rem', { lineHeight: '2.75rem', fontWeight: '700' }],
				h4: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
				h5: ['1.25rem', { lineHeight: '1.75rem', fontWeight: '500' }],
				h6: ['1rem', { lineHeight: '1.5rem', fontWeight: '500' }],
				lg: ['1.25rem', { lineHeight: '1.75rem' }],
				base: ['1rem', { lineHeight: '1.625rem' }],
				sm: ['.9375rem', { lineHeight: '1.5rem' }],
				xs: ['.875rem', { lineHeight: '1.375rem' }],
				xxs: ['.75rem', { lineHeight: '1.5rem' }],
				eyebrow: ['.75rem', { lineHeight: '1.25rem', fontWeight: '500' }],
				li: ['1rem', { lineHeight: '1.75rem' }],
				'statistic-headline': ['5.75rem', { lineHeight: '5.75rem' }],
				'quote-sm': ['1.25rem', { lineHeight: '2rem' }],
				'quote-lg': ['1.75rem', { lineHeight: '2.5rem' }],
				'byline-sm': ['.8125rem', { lineHeight: '1.5625rem' }],
				'byline-lg': ['1.0625rem', { lineHeight: '1.6875rem' }]
			}
		}
	},
	plugins: [
		require('@tailwindcss/typography'),
		require('@tailwindcss/forms'),
		require('@tailwindcss/container-queries')
	]
}
