const Share = () => (
	<svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.12977 1.41983C6.12977 1.14158 6.35534 0.916016 6.63359 0.916016H10.3282C10.6992 0.916016 11 1.21677 11 1.58777V5.28243C11 5.56068 10.7744 5.78624 10.4962 5.78624C10.2179 5.78624 9.99237 5.56068 9.99237 5.28243V2.63615L4.05091 8.57761C3.85416 8.77436 3.53516 8.77436 3.3384 8.57761C3.14165 8.38086 3.14165 8.06186 3.3384 7.86511L9.27986 1.92365H6.63359C6.35534 1.92365 6.12977 1.69808 6.12977 1.41983ZM0 3.18319C0 2.90494 0.225566 2.67937 0.503817 2.67937H4.53435C4.8126 2.67937 5.03817 2.90494 5.03817 3.18319C5.03817 3.46144 4.8126 3.68701 4.53435 3.68701H1.00763V11.0763H8.39695V7.46563C8.39695 7.18738 8.62251 6.96182 8.90076 6.96182C9.17901 6.96182 9.40458 7.18738 9.40458 7.46563V11.5801C9.40458 11.8584 9.17901 12.084 8.90076 12.084H0.503817C0.225566 12.084 0 11.8584 0 11.5801V3.18319Z"
			fill="#0D52FF"
		/>
	</svg>
)

export default Share
