const ArrowDown = ({ className = '' }) => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 12 7"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path d="M10.2875 0.5L6.40745 4.38L2.52745 0.5C2.13745 0.11 1.50745 0.11 1.11745 0.5C0.727451 0.89 0.727451 1.52 1.11745 1.91L5.70745 6.5C6.09745 6.89 6.72745 6.89 7.11745 6.5L11.7074 1.91C12.0974 1.52 12.0974 0.89 11.7074 0.5C11.3174 0.12 10.6775 0.11 10.2875 0.5V0.5Z" />
	</svg>
)

export default ArrowDown
