import * as React from 'react'
import { SVGProps } from 'react'

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg width={44} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M42 10H2m0 0 8 8m-8-8 8-8"
			stroke="currentColor"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default SvgArrowLeft
